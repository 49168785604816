#landingpage {
    width: 100vw;
    height: calc(100vh - 80px);
    margin-top: 80px;
}

.landingpageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingpageBgContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.landingpageBgMedia {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingpageTextContainer {
    width: 1400px;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
}

.landingpageTextWrapper {
    background-color: var(--darkColor);
    padding: 4rem;
}

.landingpageHeading {
    font-size: var(--fontSizeHeadingH1);
    font-family: semiBold;
    color: var(--yellowColor);
    margin-bottom: 20px;
}

.landingpageText {
    color: var(--whiteColor);
    font-size: var(--fontSizeNavbar);
    margin-bottom: 40px;
}

.landingpageCta {
    background-color: var(--yellowColor);
    color: var(--darkColor);
    font-weight: 600;
    text-decoration: none;
    padding: 12px 24px;
    border: 1px solid var(--yellowColor);
    transition: all 250ms ease;
}

.landingpageCta:hover {
    background-color: transparent;
    color: var(--whiteColor);
}

@media only screen and (max-width: 700px) {
    .landingpageTextWrapper {
        width: 100%;
        padding: 4rem 2rem;
        background-color: var(--darkColor80opacity);
    }
    .landingpageHeading {
        font-size: var(--fontSizeH2);
    }
}

@media only screen and (max-width: 450px) {
    .landingpageCta {
        width: 100%;
        display: inline-block;
        text-align: center;
    }
}
#footer {
    width: 100%;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerContainer {
    width: 1400px;
    margin: 0 20px;
}

.footerWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}

.footerItemContainer:nth-child(1) {
    width: 350px;
    max-width: 350px;
}

.footerItemContainer:nth-child(1) .footerFlexWrapper {
    margin-bottom: 50px;
}

.footerItemContainer:nth-child(1) .footerFlexWrapper>img {
    width: 180px;
    height: auto;
    margin-bottom: 10px;
}

.footerShortText {
    color: var(--whiteColor);
    font-size: var(--fontSizeStandard);
}

.footerItemContainer:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}

.footerContactContainer {
    width: 265px;
}

.footerContactHeading, .footerMenuHeading {
    color: var(--yellowColor);
    margin-bottom: 20px;
    font-size: var(--fontSizeNavbar);
}

.footerContactAddressContainer, .footerContactPhoneContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
}

.footerContactAddressContainer>svg, .footerContactPhoneContainer>svg {
    color: var(--yellowColor);
}

.footerContactAddress, .footerContactPhone, .footerContactOpenHoursDayTime, .footerContactOpenHoursDayTime {
    color: var(--whiteColor);
    font-size: var(--fontSizeStandard);
}

.footerContactAddressContainer {
    margin-bottom: 10px;
}

.footerContactPhoneContainer {
    margin-bottom: 20px;
}

.footerContactOpenHoursContainer {
    width: 100%;
}

.footerContactOpenHoursWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

iframe {
    overflow: hidden;
    border: none;
}

.footerMenuContainer {
    list-style: none;
}

.footerMenuItem {
    margin-bottom: 10px;
}

.footerMenuLink {
    text-decoration: none;
    color: var(--whiteColor);
    font-size: var(--fontSizeStandard);
    opacity: 0.8;
    transition: all 500ms ease;
    position: relative;
    display: inline-block;
    width: 100%;
}

.footerMenuLink:hover {
    transform: translateX(25px);
    opacity: 1;
}

.footerMenuLink:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    width: 10px;
    height: 2px;
    background-color: var(--yellowColor);
    transition: all 250ms ease;
}




.footerCopyrightContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding-top: 50px;
    position: relative;
}

.footerCopyrightContainer::before {
    content: '';
    position: absolute;
    width: 200px;
    height: 1px;
    top: 0px;
    left: 50%;
    background-color: var(--yellowColor);
    transform: translate(-50%, 0);
}

.footerCopyright {
    color: var(--whiteColor);
    opacity: 0.7;
}

@media only screen and (max-width: 670px) {
    .footerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .footerWrapper {
        width: 350px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

    }
}

@media only screen and (max-width: 450px) {
    .footerContactAddress, .footerContactPhone, .footerContactOpenHoursDayTime, .footerContactOpenHoursDayTime, .footerMenuLink {
        font-size: var(--fontSizeStandard16px);
    }
    .footerCopyright {
        font-size: var(--fontSizefooterMobil);
    }
    .footerWrapper {
        width: 320px;
    }
    .footerItemContainer:nth-child(1), .footerItemContainer:nth-child(2), .footerItemContainer:nth-child(3), iframe {
        width: 100%;
    }
}
#cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

.cardsContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 20px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cardsSingleContainer {
    border: 2px solid var(--darkColor);
    padding: 40px;
    width: calc((100% / 4) - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.cardsSingleContainer:nth-child(odd) {
    background-color: var(--darkColor);
    position: relative;
}

.cardsSingleContainer:nth-child(odd)::before {
    background-color: transparent;
    content: '';
    width: 100%;
}

.cardsSingleImageContainer {
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--whiteColor);
    border-radius: 50%;
    padding: 10px;
    overflow: hidden;
}

.cardsSingleImageContainer>img {
    width: 100%;
    height: 100%;
}

.cardsSingleHeading {
    font-size: var(--fontSizeNavbar);
    color: var(--yellowColor);
    font-family: semiBold;
    margin-bottom: 20px;
}

.cardsSingleContainer:nth-child(even) .cardsSingleHeading {
    font-size: var(--fontSizeNavbar);
    color: var(--darkColor);
    font-family: semiBold;
    margin-bottom: 20px;
}

.cardsSingleText {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
}

.cardsSingleContainer:nth-child(odd) .cardsSingleText {
    color: var(--whiteColor);
}

@media only screen and (max-width: 1000px) {
    .cardsSingleContainer {
        width: calc((100% / 2) - 20px);
        height: 300px;
    }
    .cardsContainer {
        height: 680px;
    }
}

@media only screen and (max-width: 550px) {
    .cardsSingleContainer {
        width: 100%;
    }
    .cardsContainer {
        height: 1280px;
    }
}
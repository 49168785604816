@font-face {
  font-family: regular;
  src: url(./fonts/JosefinSans-Regular.ttf);
}

@font-face {
  font-family: semiBold;
  src: url(./fonts/JosefinSans-SemiBold.ttf);
}

@font-face {
  font-family: boldFont;
  src: url(./fonts/JosefinSans-Bold.ttf);
}

:root {
  --whiteColor: #EFEFEF;
  --whiteColor70opacity: #dadadacc;
  --darkColor: #212529;
  --darkColor80opacity: #212529ce;
  --darkColor50opacity: #2125299c;
  --darkColor20opacity: #21252936;
  --yellowColor: #D3AC2B;
  --yellowColor50opacity: #d3ac2b88;
  --greenColor: #05b905;
  --errorColor: #F0706A;
  --fontSizeNavbar: 20px;
  --fontSize24px: 24px;
  --fontSizePrice: 32px;
  --fontSizeStandard: 18px;
  --fontSizeStandard16px: 16px;
  --fontSizefooterMobil: 14px;
  --fontSizeH2: 42px;
  --fontSizeHeadingH1: 64px;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: regular;
}

.App {
  background-color: var(--whiteColor);
}

.navMainContainer {
  width: 100%;
  height: 80px;
  background-color: var(--darkColor);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  /* overflow: hidden; */
}

.mainContent {
  overflow: hidden;
}

.footerMainContainer {
    width: 100%;
    background-color: var(--darkColor);
    overflow: hidden;
}

#products {
    width: 100%;
    padding: 150px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productsContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 20px;
}

.productsHeading {
    color: var(--darkColor);
    font-size: var(--fontSizeHeadingH1);
    font-family: semiBold;
    margin-bottom: 100px;
}

.productsFlexWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 50px;
}

.productsSingleContainer {
    width: calc((100% / 4) - 40px);
    height: 600px;
}

.productsSingleImageContainer {
    width: 100%;
    height: 400px;
}

.productsSingleImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productsSingleTextContainer {
    margin-top: 10px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.productsSingleNamePriceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.productsSingleName {
    color: var(--darkColor);
    font-size: var(--fontSizeNavbar);
}

.productsSinglePrice {
    color: var(--greenColor);
    font-size: var(--fontSizeStandard);
    font-family: boldFont;
}

.productsSingleShortText {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
    margin-bottom: 10px;
}

.productsSingleBtnContainer {
    width: 100%;
}

.productsSingleBtn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkColor);
    color: var(--yellowColor);
    text-decoration: none;
    font-size: var(--fontSizeStandard);
    border: 1px solid var(--darkColor);
    transition: all 250ms ease;
}

.productsSingleBtn:hover {
    color: var(--darkColor);
    background-color: var(--whiteColor);
}

@media only screen and (max-width: 1200px) {
    .productsSingleContainer {
        width: calc((100% / 3) - 40px);
    }
}

@media only screen and (max-width: 880px) {
    .productsHeading {
        font-size: var(--fontSizeH2);
    }
    .productsFlexWrapper {
        align-items: center;
        justify-content: space-between;
    }
    .productsSingleContainer {
        width: calc((100% / 2) - 40px);
        max-width: 300px;
    }
}

@media only screen and (max-width: 550px) {
    .productsFlexWrapper {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .productsSingleContainer {
        width: 100%;
        max-width: 300px;
    }
}
#reviews {
    width: 100%;
    padding-bottom: 100px;
}

.reviewsContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rewiewsWrapper{
    width: 100%;
}

.reviewsHeading {
    font-size: var(--fontSizeH2);
    font-family: semiBold;
    color: var(--darkColor);
    margin-bottom: 10px;
}

.reviewsSubheading {
    font-size: var(--fontSizeNavbar);
    color: var(--darkColor);
    margin-bottom: 75px;
}

.reviewsSlideContainerWrapper, .reviewscarousel-container {
    width: 100%;
}

.reviewsSlideContainer {
    width: 400px;
}

.reviewsSlideRatingContainer {
    width: 150px;
    margin-bottom: 20px;
}

.reviewsSlideRatingContainer>img {
    width: 100%;
    height: auto;
}

.reviewsSlideText>p {
    font-family: boldFont;
    font-size: var(--fontSizeNavbar);
    color: var(--darkColor);
    margin-bottom: 20px;
}

.reviewsSlideImageContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
}

.reviewsSlideImageContainer img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.reviewsSlideName {
    color: var(--darkColor);
    font-family: regular;
    
}

@media only screen and (max-width: 1400px) {
    .reviewsContainer {
        width: 100%;
        margin: 0 20px;
    }
}

@media only screen and (max-width: 450px) {
    .reviewsSlideContainer {
        width: 100%;
        padding-right: 50px;
    }
    .reviewsSubheading{
        padding-right: 20px;
    }
}


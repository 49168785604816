#order {
    width: 100%;
    padding: 150px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 20px;
}

.orderHeading {
    font-size: var(--fontSizeH2);
    color: var(--darkColor);
    font-family: semiBold;
    margin-bottom: 50px;
}

.orderWrapper {
    display: flex;
    justify-content: space-between;
    gap: 8rem;
    width: 100%;
}

/* FORM style */

.orderFormContainer{
    width: 50%;
}

.orderForm {
    width: 100%;
}

.orderFormItemGroupContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 20px;
}

.orderFormItemGroupContainer>.orderFormItemContainer {
    width: 50%;
}

.orderFormLabelErrorMsgContainer {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.orderFormLabelErrorMsgContainer>label {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
    font-family: semiBold;
}

.inputErrorMsg {
    color: var(--errorColor);
    display: flex;
    align-items: center;
}

.inputErrorMsg>svg {
    margin-right: 3px;
}

.orderFormItemContainer>input {
    width: 100%;
    padding: 8px 16px;
    font-size: var(--fontSizeStandard);
    outline: none;
    border: 1px solid var(--darkColor20opacity);
}

.orderFormDeliveryContainer {
    margin: 50px 0 10px;
    background-color: var(--darkColor20opacity);
    padding: 20px;
    width: 100%;
}

.orderFormDeliveryIconContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 10px;
}

.orderFormDeliveryHeading {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
    font-family: semiBold;
}

.orderFormDeliveryText, .orderFormPolicyText {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
}

.orderFormPolicyContainer {
    margin-bottom: 50px;
    background-color: var(--darkColor20opacity);
    padding: 20px;
    width: 100%;
}

.orderFormBtnContainer {
    width: 100%;
}

.orderFormSuccesMsg {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--greenColor);
    font-family: semiBold;
}

.orderFormBtn{
    width: 100%;
    border: 1px solid var(--darkColor);
    background-color: var(--darkColor);
    color: var(--whiteColor);
    font-size: var(--fontSize24px);
    height: 75px;
    font-family: semiBold;
    cursor: pointer;
    transition: all 250ms ease;
}

.orderFormBtn.soldOut{
    width: 100%;
    border: 1px solid var(--errorColor);
    background-color: var(--errorColor);
    color: var(--whiteColor);
    font-size: var(--fontSize24px);
    height: 75px;
    font-family: semiBold;
    cursor: default;
    transition: all 250ms ease;
}

.orderFormBtn:hover {
    background-color: transparent;
    color: var(--darkColor);
}

.orderFormBtn.soldOut:hover {
    background-color: var(--errorColor);
    color: var(--whiteColor);
}

/* PRODUCT style */

.orderProductContainer {
    width: 30%;
}

.orderProductHeding {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
    margin-bottom: 20px;
}

.orderProductWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    padding: 20px 0;
    margin-bottom: 20px;
    border-top: 1px solid var(--darkColor20opacity);
    border-bottom: 1px solid var(--darkColor20opacity);
}

.orderProductImageContainer {
    width: 75px; 
}

.orderProductImageContainer>img {
    width: 100%;
    height: auto; 
}

.orderProductTextName {
    color: var(--darkColor);
    margin-bottom: 10px;
}

.orderProductTextBrndName {
    color: var(--darkColor);
    margin-bottom: 5px;
}

.orderProductTotalContainer {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--darkColor20opacity);
}

.orderProductTotalWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderProductTotalWrapper:first-child {
    margin-bottom: 10px;
}

.orderProductTotalText, .orderProductTotalPrice {
    color: var(--darkColor);
    font-family: boldFont;
    font-size: var(--fontSizeStandard);
}

#pickup {
    width: 200px;
    font-size: var(--fontSizeStandard);
    outline: none;
}

#ringSize {
    width: 60px;
    font-size: var(--fontSizeStandard);
    margin-top: 5px;
}

.orderFormStockSize {
    color: var(--darkColor);
    font-family: semiBold;
}

@media only screen and (max-width: 964px) {
    .orderFormContainer{
        width: 70%;
    }
    .orderWrapper {
        gap: 2rem;
    }
}

@media only screen and (max-width: 700px) {
    .orderFormContainer{
        width: 100%;
    }
    .orderWrapper {
        flex-direction: column-reverse;
        gap: 4rem;
    }
    .orderProductContainer {
        width: 100%;
    }
    .orderHeading {
        font-size: var(--fontSizePrice);
    }
}

@media only screen and (max-width: 500px) {
    .orderFormItemGroupContainer {
        flex-direction: column;
    }
    .orderFormItemGroupContainer>.orderFormItemContainer {
        width: 100%;
    }
}
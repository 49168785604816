#bdCat {
    width: 100%;
    padding: 200px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bdCatContainer {
    width: 1200px;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 20px;
}

.bdCatSingleContainer  {
    width: calc((100% / 3) - 50px);
    height: 400px;
    position: relative;
    margin-bottom: 75px;
}

.bdCatSingleContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.bdCatSingleWrapper {
    width: 100%;
    height: 100%;
    background-color: var(--darkColor50opacity);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bdCatSingleFlexCon {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bdCatSingleName {
    color: var(--yellowColor);
    font-size: var(--fontSizeH2);
    font-family: semiBold;
    margin-bottom: 20px;
}

.bdCatSingleBtn {
    border: 1px solid var(--yellowColor);
    padding: 12px 75px;
    background-color: var(--darkColor);
    text-decoration: none;
    color: var(--yellowColor);
    font-size: var(--fontSizeStandard);
}

@media only screen and (max-width: 1050px) {
    #bdCat {
        padding: 120px 0;
    }
    .bdCatSingleContainer  {
        width: calc((100% / 3) - 25px);
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 930px) {
    .bdCatSingleContainer  {
        width: calc((100% / 2) - 30px);
        margin-bottom: 55px;
    }
}

@media only screen and (max-width: 650px) {
    .bdCatSingleContainer  {
        width: 100%;
        margin-bottom: 30px;
    }
}


#news {
    width: 100%;
    padding: 150px 0;
}

.newsContainer {
    padding: 0 20px;
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* TEXT section */

.newsTextContainer {
    width: 1400px;
    height: 100%;
    margin: 0 20px;
    display: flex;
    align-items: center;
}

.newsTextWrapper {
    max-width: 600px;
}

.newsHeading {
    font-size: var(--fontSizeH2);
    font-family: semiBold;
    color: var(--darkColor);
    margin-bottom: 20px;
}

.newsShorttext {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
    margin-bottom: 40px;
}

.newsTextWrapper a {
    border: 1px solid var(--yellowColor);
    padding: 12px 24px;
    text-decoration: none;
    transition: all 250ms ease;
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
    background-color: var(--yellowColor);
    font-family: semiBold;
}

.newsTextWrapper a:hover {
    color: var(--darkColor);
    background-color: transparent;
}


/* SLIDE section */

.newsSlideBtnContainer {
    display: none;
}

.test {
    display: flex;
    align-items: center;
}

.newsSlideContainer {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    transform: translate(0, -50%);
}

.newscarousel-container {
    width: 100%;
    max-height: 700px;
}

.newscarousel-container>ul {
    height: 100%;
}

.newscarousel-container>ul>li {
    height: 100%;
}

.newsSingleSlideContainer {
    max-width: 400px;
    height: 700px;
    margin: 0 20px;
}

.newsSingleSlideImageContainer {
    width: 100%;
    max-height: 525px;
}

.newsSingleSlideImageContainer>img {
    width: 100%;
    max-height: 525px;
    object-fit: cover;
}

.newsSingleSlideheadingContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.newsSingleSlideHeading {
    font-weight: 600;
    font-size: 22px;
    color: #FFD700;
}


.newsSingleSlidePrice {
    font-weight: 500;
    color: var(--greenColor);
    font-size: 20px;
}

.newsSingleSlideText {
    width: 100%;
    color: var(--whiteColor);
    margin-bottom: 10px;
    font-size: var(--fontSizeStandard);
}

.newsSlideBtn {
    display: inline-block;
    text-align: center;
    width: 100%;
    text-decoration: none;
    padding: 10px 24px;
    border: 1px solid var(--yellowColor);
    color: var(--darkColor);
    background-color: var(--yellowColor);
    font-family: semiBold;
    font-size: var(--fontSizeStandard);
}

.newsSlideBtn>:hover {
    color: var(--whiteColor);
    background-color: transparent;
}

.newsSingleSlideTextContainer {
    margin-top: 10px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.newsSingleSlideNamePriceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.newsSingleSlideName {
    color: var(--darkColor);
    font-size: var(--fontSizeNavbar);
}

.newsSingleSlidePrice {
    color: var(--greenColor);
    font-size: var(--fontSizeStandard);
    font-family: boldFont;
}

.newsSingleSlideTextWrapper {
    margin-bottom: 10px;
}

.newsSingleSlideText {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
}

.newsSingleSlideBtnContainer {
    width: 100%;
}

.newsSingleSlideBtn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkColor);
    color: var(--yellowColor);
    text-decoration: none;
    font-size: var(--fontSizeStandard);
    border: 1px solid var(--darkColor);
    transition: all 250ms ease;
}

.newsSingleSlideBtn:hover {
    color: var(--darkColor);
    background-color: var(--whiteColor);
}

@media only screen and (max-width: 1400px) {
    .newsTextContainer {
        width: 50%;
    }
    .newsSlideContainer {
        width: 50%;
        position: relative;
        height: 100%;
        
    }
}

@media only screen and (max-width: 850px) {
    .newsContainer {
        flex-direction: column;
        height: 900px;
    }
    .newsTextContainer {
        width: 100%;
    }
    .newsSlideContainer {
        width: 100%;
    }
}
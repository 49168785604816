#about {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.aboutContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aboutTextContentContainer {
    width: 50%;
}

.aboutTextWrapper{
    width: 500px;
}

.aboutHeading {
    font-size: var(--fontSizeH2);
    font-family: semiBold;
    color: var(--darkColor);
    margin-bottom: 20px;
}

.aboutText {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
}

.aboutImageContainer {
    width: 50%;
}

.aboutMedia{
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1000px) {
    .aboutContainer {
        flex-direction: column;
    }
    .aboutTextContentContainer {
        width: 100%;
        margin-bottom: 50px;
    }
    .aboutTextWrapper{
        width: 70%;
    }
    .aboutImageContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .aboutContainer {
        flex-direction: column;
    }
    .aboutTextContentContainer {
        width: 100%;
        margin-bottom: 50px;
    }
    .aboutTextWrapper{
        width: 100%;
    }
    .aboutImageContainer {
        width: 100%;
    }
}
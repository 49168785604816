.hamburgerLines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 2;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--yellowColor);
}

.line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.line2 {
    transition: transform 0.2s ease-in-out;
}

.line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.line1.active {
    transform: rotate(45deg);
}

.line2.active {
    transform: scaleY(0);
}

.line3.active {
    transform: rotate(-45deg);
}

@media only screen and (min-width: 964px) {
    .hamburgerLines {
        display: none;
    }
}
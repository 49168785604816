#singleProduct {
    width: 100%;
    padding: 150px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleProductContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.singleProductImageContainer {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleProductImageWrapper {
    width: 50%;
    height: fit-content;
    cursor: pointer;
}

.singleProductImageWrapper>img {
    width: 100%;
    height: auto;
}

.singleProductImagesContainer {
    width: 75px;
    height: 100%;
    margin-right: 29px;
}

.singleProductImagesWrapper {
    width: 100%;
    height: fit-content;
    cursor: pointer;
    border: 1px solid var(--darkColor20opacity);
}

.singleProductImagesWrapper>img {
    width: 100%;
    height: auto;
}

.singleProductContentContainer {
    width: 40%;
}

.singleProductName {
    color: var(--darkColor);
    font-size: var(--fontSizeH2);
    font-family: semiBold;
    margin-bottom: 20px;
}

.singleProductBrandName {
    color: var(--darkColor);
    font-family: boldFont;
    margin-bottom: 20px;
}

.singleProductLongText {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
    margin-bottom: 20px;
}

.singleProductSellingpointsContainer {
    width: 100%;
    margin-bottom: 50px;
}

.singleProductSellingpointWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.singleProductSellingpointWrapper>img {
    height: 20px;
    width: auto;
}

.singleProductSellingpoint {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
}

.singleProductPrice {
    font-size: var(--fontSizePrice);
    color: var(--greenColor);
    font-family: boldFont;
    margin-bottom: 50px;
}

.singleProductPrice>span {
    color: var(--darkColor);
}

.singleProductBtnContainerBaggedesign {
    width: 100%;
    padding: 24px 24px;
    border: 1px solid var(--darkColor);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkColor20opacity);
}

.singleProductBtnTextBaggedesign {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
    font-family: semiBold;
}

.singleProductBtnContainer {
    width: 100%;
    padding: 24px 24px;
    border: 1px solid var(--greenColor);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--greenColor);
    cursor: pointer;
    transition: all 250ms ease;
    text-decoration: none;
}

.singleProductBtnContainer:hover{
    background-color: var(--whiteColor);
}

.singleProductBtnContainer:hover .singleProductBtnText {
    color: var(--darkColor);
}

.singleProductBtnContainer.soldOut:hover .singleProductBtnText {
    color: var(--whiteColor);
}

.singleProductBtnContainer.soldOut{
    width: 100%;
    border: 1px solid var(--errorColor);
    background-color: var(--errorColor);
    color: var(--whiteColor);
    font-size: var(--fontSize24px);
    height: 75px;
    font-family: semiBold;
    cursor: default;
    transition: all 250ms ease;
}

.singleProductBtnContainer.soldOut:hover {
    background-color: var(--errorColor);
    color: var(--whiteColor);
}

.singleProductBtnText {
    color: var(--whiteColor);
    font-size: var(--fontSize24px);
    font-family: semiBold;
    transition: all 250ms ease;
}

.singleProductStockSize {
    margin-bottom: 20px;
    color: var(--darkColor);
    font-family: semiBold;
}

/* Modal container */

.modalContainer {
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: var(--whiteColor70opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    padding: 100px 0;
}

.modalContainer.active {
    display: flex;
}

.modalCloseContainer {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 65px;
    height: 65px;
    background-color: var(--darkColor);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeModalIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 250ms ease;
}

.closeModalIcon:hover {
    transform: scale(1.15);
}

.modalWrapper {
    max-width: 1000px;
    width: 1000px;
    max-height: 90%;
    height: 90%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalArrowContainer {
    width: 60px;
    height: 60px;
    background-color: var(--darkColor);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalArrow {
    width: 40px;
    height: auto;
    color: var(--yellowColor);
    cursor: pointer;
    transition: all 250ms ease;

}

.modalArrow:hover {
    transform: scale(1.15);
}

.modalImage {
    width: calc(100% - 140px);
    height: 100%;
    object-fit: contain;
    margin: 0 10px;
}

@media only screen and (max-width: 850px) {
    .singleProductContainer {
        flex-direction: column-reverse;
        gap: 4rem;
    }
    .singleProductContentContainer, .singleProductImageContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .modalArrowContainer {
        width: 40px;
        height: 40px;
    }
    .modalArrowContainer>svg {
        width: 25px;
    }
    .modalArrowContainer:nth-child(1)>svg {
        margin-right: 4px;
    }
    .modalArrowContainer:nth-child(2)>svg {
        margin-left: 4px;
    }
    .modalCloseContainer {
        top: 30px;
        right: 30px;
        width: 55px;
        height: 55px;
    }
    .modalWrapper {
        position: relative;
        width: 100%;
    }
    .modalWrapper>img {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        width: calc(100% - 50px);
        padding-left: 10px;
    }
}
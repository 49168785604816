#fpBybaggesProds {
    width: 100%;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fpBybaggesProdsContainer {
    max-width: 1400px;
    width: 1400px;
    margin: 0 20px;
}

.fpBybaggesProdsHeading {
    font-size: var(--fontSizeH2);
    color: var(--darkColor);
    font-family: semiBold;
    margin-bottom: 50px;
}

.fpBybaggesProdsProductsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.fpBybaggesProdsSingleContainer {
    width: calc((100% / 4) - 40px);
    height: 600px;
}

.fpBybaggesProdsSingleImageContainer {
    width: 100%;
    height: 400px;
}

.fpBybaggesProdsSingleImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fpBybaggesProdsSingleTextContainer {
    margin-top: 10px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fpBybaggesProdsSingleNamePriceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.fpBybaggesProdsSingleName {
    color: var(--darkColor);
    font-size: var(--fontSizeNavbar);
}

.fpBybaggesProdsSinglePrice {
    color: var(--greenColor);
    font-size: var(--fontSizeStandard);
    font-family: boldFont;
}

.fpBybaggesProdsSingleTextWrapper {
    margin-bottom: 10px;
}

.fpBybaggesProdsSingleText {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
}

.fpBybaggesProdsSingleBtnContainer {
    width: 100%;
}

.fpBybaggesProdsSingleBtn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkColor);
    color: var(--yellowColor);
    text-decoration: none;
    font-size: var(--fontSizeStandard);
    border: 1px solid var(--darkColor);
    transition: all 250ms ease;
}

.fpBybaggesProdsSingleBtn:hover {
    color: var(--darkColor);
    background-color: var(--whiteColor);
}

.fpBybaggesProdsBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fpBybaggesProdsBtn {
    padding: 12px 28px ;
    background-color: var(--whiteColor);
    color: var(--darkColor);
    text-decoration: none;
    font-size: var(--fontSizeStandard);
    border: 1px solid var(--darkColor);
    transition: all 250ms ease;
    font-family: semiBold;
}

.fpBybaggesProdsBtn:hover {
    background-color: var(--darkColor);
    color: var(--yellowColor);
}

@media only screen and (max-width: 1000px) {
    .fpBybaggesProdsSingleContainer {
        width: calc((100% / 3) - 40px);
    }
}

@media only screen and (max-width: 800px) {
    .fpBybaggesProdsSingleContainer {
        width: calc((100% / 2) - 40px);
    }
}

@media only screen and (max-width: 650px) {
    .fpBybaggesProdsSingleContainer {
        width: calc((100% / 2) - 20px);
    }
}

@media only screen and (max-width: 500px) {
    .fpBybaggesProdsSingleContainer {
        width: 310px;
    }
    .fpBybaggesProdsProductsContainer{
        flex-direction: column;
    }
    .fpBybaggesProdsHeading{
        font-size: var(--fontSizePrice);
    }
}

#contact {
    width: 100%;
    height: fit-content;
    background-color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactContainer {
    width: 1400px;
    padding: 150px 0;
    margin: 0 20px;
}

.contactHeadingContainer {
    margin-bottom: 50px;
}

.contactHeading {
    font-size: var(--fontSizeH2);
    font-family: boldFont;
    color: var(--darkColor);
    margin-bottom: 20px;
}

.contactText {
    font-size: var(--fontSizeStandard);
    color: var(--darkColor);
}

.contactContentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}

.contactPlaceContainer {
    max-width: 900px;
}

.contactPlaceTextContainer {
    width: 40%;
}

.contactPlaceImageContainer {
    width: 60%;
    position: relative;
}

.contactPlaceImageContainer>img {
    width: 100%;
    height: auto;
}

.contactPlaceImageContainer>iframe {
    width: 60%;
    height: 40%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: none;
}

.contactPlaceHeading {
    color: var(--darkColor);
    font-size: var(--fontSize24px);
    font-family: semiBold;
    margin-bottom: 20px;
}

.contactPlaceWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contactPlaceTextFlex{
    margin-bottom: 30px;
}

.contactPlaceAddressContainer, .contactPlacePhoneContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.contactPlaceAddressContainer>svg {
    width: 20px;
    height: auto;
}

.contactPlacePhoneContainer>svg {
    width: 18px;
    height: auto;
}

.contactPlaceAddressContainer {
    margin-bottom: 10px;
}

.contactPlaceAddress {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
}

.contactPlaceTextFlex {
    width: 300px;
    max-width: 300px;
}

.contactPlaceOpenHoursWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.contactPlaceOpenHoursDayTime {
    color: var(--darkColor);
    font-size: var(--fontSizeStandard);
}

@media only screen and (max-width: 820px) {
    .contactPlaceWrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 560px) {
    .contactPlaceImageContainer {
        width: 100%;
    }
    .contactHeading {
        font-size: var(--fontSizePrice);
    }
}
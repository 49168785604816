#fpBybagges {
    width: 100%;
    padding: 150px 0;
}

.fpBybaggesContainer {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.fpBybaggesTextContainer {
    width: 1400px;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: end;
}

.fpBybaggestextWrapper {
    width: 900px;
    background-color: var(--darkColor);
    position: relative;
    z-index: 10;
    padding: 50px 100px;
}

.fpBybaggesHeading {
    font-size: var(--fontSizeH2);
    color: var(--yellowColor);
    font-family: semiBold;
    margin-bottom: 20px;
}

.fpBybaggesText {
    font-size: var(--fontSizeStandard);
    color: var(--whiteColor);
    font-family: regular;
    line-height: 1.5;
}

.fpBybaggesPointersSingleWrapper{
    display: flex;
    margin: 30px 0 30px 20px;
}

.fpBybaggesPointersSingleWrapper>img {
    width: auto;
    height: 20px;
    margin-right: 10px;
}

.fpBybaggesPointersSingleText{
    color: var(--whiteColor);
    font-size: var(--fontSizeStandard);
}

.fpBybaggesMediaContainer {
    width: 1100px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.fpBybaggesMedia {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1400px) {
    #fpBybagges {
        padding: 0;
    }
    .fpBybaggesMediaContainer {
        position: relative;
        width: 100%;
        transform: none;
    }
    .fpBybaggesContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .fpBybaggesTextContainer {
       transform: translateY(-300px);
       z-index: 20;
       width: 100%;
    }
}

@media only screen and (max-width: 964px) {
    .fpBybaggesTextContainer {
       transform: translateY(-100px);
    }
    .fpBybaggestextWrapper{
        width: 100%;
    }
}

@media only screen and (max-width: 800px) {
    .fpBybaggestextWrapper{
        padding: 50px 50px;
    }
    .fpBybaggesHeading {
        font-size: var(--fontSizePrice);
    }
}

@media only screen and (max-width: 550px) {
    .fpBybaggestextWrapper{
        padding: 50px 20px;
    }
    .fpBybaggesHeading {
        font-size: var(--fontSizePrice);
    }
    .fpBybaggesTextContainer {
        transform: translateY(-10px);
     }
}